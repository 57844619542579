import React from 'react';
import styled from 'styled-components';
import SvgArrowDown from '@ntuctech/devex-tangram/Icon/SvgArrowDown';
import { Typography } from '@ntuctech/devex-tangram';

const StyledAccordion = styled.div`
  border: 1px solid #eaeaea;
  border-radius: 4px;
  min-height: 3.5rem;
  background-color: white;
  padding: ${(props) => (props.b2b ? '0.75rem 1rem' : '1rem')};
  display: block;
  transition: var(--transition-duration);
  margin-bottom: ${(props) => (props.b2b ? '0.75rem' : '0.5rem')};
  width: 100%;
  cursor: pointer;
  &.custom-accordion {
    border: none;
    padding: 0;
    margin-bottom: 3rem;

    div[class*='Accordion__AccordionHeader'] {
      background-color: #f6f6f6;
      padding: 1rem;
    }

    div[class*='Accordion__AccordionBody'][data-visible='true'] {
      padding: 0;
    }

    h6 {
      font-size: 1.125rem;
      line-height: 1.75rem;
      color: #333;
    }
  }
`;

const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;
const AccordionArrow = styled(SvgArrowDown)`
  transition: all ease-in-out 0.2s;

  &[data-expand='true'] {
    transform: rotate(180deg);
  }
  @media print {
    transform: rotate(180deg);
  }
`;

const AccordionTitle = styled(Typography)`
  color: #1454b8;
`;
const showContents = `
  overflow: visible;
  opacity: 1;
  max-height: 1000vh;
  padding-top: 1rem;
`;
const AccordionBody = styled.div`
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all ease-in-out 0.5s;
  color: #333;
  cursor: default;

  &[data-visible='true'] {
    ${showContents}
  }
  @media print {
    ${showContents}
  }
`;

const Accordion = ({
  callback = null,
  children,
  title,
  expand: initialExpand = false,
  b2b = false,
  className = '',
  hash,
}) => {
  const [expand, setExpand] = React.useState(initialExpand);

  const handleExpandToggle = () => {
    setExpand(!expand);
    if (!expand && callback) {
      callback();
    }
  };

  return (
    <StyledAccordion
      b2b={b2b}
      data-testid={`accordion-item-${title}`}
      data-expand={expand}
      onClick={handleExpandToggle}
      className={className}
    >
      <AccordionHeader data-testid={`accordion-${title}`} id={hash}>
        <AccordionTitle variant={b2b ? 'h5' : 'h6'}>{title}</AccordionTitle>
        <AccordionArrow data-expand={expand} color="#1454B8" />
      </AccordionHeader>
      <AccordionBody
        onClick={(e) => e.stopPropagation()}
        data-testid={`accordion-body-${title}`}
        data-visible={expand}
      >
        {children}
      </AccordionBody>
    </StyledAccordion>
  );
};

export default Accordion;
