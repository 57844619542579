import React from 'react';
import styled from 'styled-components';
import { Typography } from '@ntuctech/devex-tangram';

import Success from '../icons/svg/Success.svg';
import Recommended from '../icons/svg/recommended.svg';

const ORDER_STATUS_HISTORY = 'Order status history';

const RecommendedContainer = styled.div`
  width: 6.063rem;
  padding-top: 0.25rem;
  margin: 0 auto;
`;

// eslint-disable-next-line import/prefer-default-export
export const headers = [
  <Typography className="relative-top" variant="titleS">
    Payment method
  </Typography>,
  <Typography variant="titleS">
    Credit or Debit card
    <RecommendedContainer>
      <img src={Recommended.src} alt="Recommended" />
    </RecommendedContainer>
  </Typography>,
  <Typography className="relative-top" variant="titleS">
    Bank transfer
  </Typography>,
];
export const rows = [
  {
    id: 0,
    values: ['Processing time', 'Instant', '2-5 working days'],
  },
  {
    id: 1,
    values: ['Voucher quantity limit (per order)', '400,000', '400,000'],
  },
  {
    id: 2,
    values: ['Max. value per voucher', '$1,000', '$1,000'],
  },
  {
    id: 3,
    values: [
      ORDER_STATUS_HISTORY,
      <img src={Success.src} className="img" alt="success" />,
      <img src={Success.src} className="img" alt="success" />,
    ],
  },
];

export const mobileRows = [
  {
    title: 'Processing time',
    body: 'Instant',
  },
  {
    title: 'Voucher quantity limit (per order)',
    body: '400,000',
  },
  {
    title: 'Max. value per voucher',
    body: '$1,000',
  },
  {
    title: ORDER_STATUS_HISTORY,
    body: <img src={Success.src} className="img" alt="success" />,
  },
];

export const mobileRowsBankTransfer = [
  {
    title: 'Processing time',
    body: '2-5 working days',
  },
  {
    title: 'Voucher quantity limit (per order)',
    body: '400,000',
  },
  {
    title: 'Max. value per voucher',
    body: '$1,000',
  },
  {
    title: ORDER_STATUS_HISTORY,
    body: <img src={Success.src} className="img" alt="success" />,
  },
];
